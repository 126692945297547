import React, { useCallback, useEffect, useState } from "react";
import Routes from "./routes";
import { Provider } from "react-redux";
import { Provider as PaperProvider } from "react-native-paper";

import { store } from "./store";
import awsExports from "./aws-exports";

import { Appearance, Platform, StatusBar, View } from "react-native";
import { Theme } from "./styles";
import { Sizes } from "../shared/constants/sizes";
import Text from "../shared/atoms/Text";
import { Toast } from "react-native-toast-message/lib/src/Toast";
import { ToastConfigParams } from "react-native-toast-message";
import * as Sentry from "sentry-expo";
import { sentryConfig } from "./services/sentry";
import * as SplashScreen from "expo-splash-screen";
import * as Updates from "expo-updates";
import { Amplify, AuthModeStrategyType, DataStore } from "aws-amplify";
import { SafeAreaProvider } from "react-native-safe-area-context";

// https://stackoverflow.com/questions/62512237/how-to-use-aws-amplify-in-react-native-with-typescript-project
// @ts-ignore

Amplify.configure({
  ...awsExports,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  },
});

if (Platform.OS !== "ios") {
  Sentry.init(sentryConfig);
  Platform.OS === "web"
    ? Sentry.Browser.setContext("App Info", {
        runtime_version: Updates.runtimeVersion,
        buildId: Updates.updateId,
      })
    : Sentry.Native.setContext("App Info", {
        runtime_version: Updates.runtimeVersion,
        buildId: Updates.updateId,
      });
}

function App() {
  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    async function prepare() {
      try {
        await DataStore.start();
        console.info("Start - DataStore");
      } catch (e) {
        console.warn(e);
      } finally {
        setAppIsReady(true);
      }
    }
    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  if (!appIsReady) {
    return null;
  }

  const toastConfig = {
    success: (props: ToastConfigParams<any>) => (
      <View
        style={{
          height: 60,
          width: "90%",
          backgroundColor: Theme.colors.title,
          marginHorizontal: Sizes.marginL,
          borderLeftColor: Theme.colors.success,
          borderLeftWidth: 5,
          padding: Sizes.paddingM,
          paddingLeft: Sizes.paddingL,
          borderColor: Theme.colors.border,
          borderWidth: 1,
          borderRadius: Sizes.borderRadiusS,
          justifyContent: "space-around",
        }}
      >
        <Text
          style={{
            fontSize: Sizes.h4,
            fontWeight: "700",
            color: Theme.colors.text_dark,
            marginBottom: 10,
          }}
        >
          {props.text1}
        </Text>
        <Text style={{ fontSize: Sizes.body4, color: Theme.colors.text_dark }}>
          {props.text2}
        </Text>
      </View>
    ),

    error: (props: ToastConfigParams<any>) => (
      <View
        style={{
          height: 75,
          width: "90%",
          backgroundColor: Theme.colors.title,
          marginHorizontal: Sizes.marginL,
          borderLeftColor: Theme.colors.error,
          borderLeftWidth: 5,
          padding: Sizes.paddingM,
          paddingLeft: Sizes.paddingL,
          borderColor: Theme.colors.border,
          borderWidth: 1,
          borderRadius: Sizes.borderRadiusS,
          justifyContent: "space-around",
        }}
      >
        <Text
          style={{
            fontSize: Sizes.h4,
            fontWeight: "700",
            color: Theme.colors.text_dark,
            marginBottom: 10,
          }}
        >
          {props.text1}
        </Text>
        <Text style={{ fontSize: Sizes.body4, color: Theme.colors.text_dark }}>
          {props.text2}
        </Text>
      </View>
    ),

    warning: (props: ToastConfigParams<any>) => (
      <View
        style={{
          height: 75,
          width: "90%",
          backgroundColor: Theme.colors.title,
          marginHorizontal: Sizes.marginL,
          borderLeftColor: Theme.colors.warning,
          borderLeftWidth: 5,
          padding: Sizes.paddingM,
          paddingLeft: Sizes.paddingL,
          borderColor: Theme.colors.border,
          borderWidth: 1,
          borderRadius: Sizes.borderRadiusS,
          justifyContent: "space-around",
        }}
      >
        <Text
          style={{
            fontSize: Sizes.h4,
            fontWeight: "700",
            color: Theme.colors.text_dark,
            marginBottom: 10,
          }}
        >
          {props.text1}
        </Text>
        <Text style={{ fontSize: Sizes.body4, color: Theme.colors.text_dark }}>
          {props.text2}
        </Text>
      </View>
    ),
  };

  return (
    <SafeAreaProvider>
      <PaperProvider>
        <Provider store={store}>
          <View
            style={{
              flex: 1,
              backgroundColor: "rgba(0,0,0,0.5)",
              paddingTop:
                Platform.OS === "android" ? StatusBar.currentHeight : 0,
            }}
            onLayout={onLayoutRootView}
          >
            <StatusBar
              translucent
              barStyle={
                Appearance.getColorScheme() === "light"
                  ? "light-content"
                  : "dark-content"
              }
            />
            <Routes />
          </View>
        </Provider>
        <Toast config={toastConfig} />
      </PaperProvider>
    </SafeAreaProvider>
  );
}

export default App;
